export const DATE_UNITS = {
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1, // un segundo == un segundo :D
};

export const CHECKOUT_STEPS = {
  delivery: {
    name: 'Entrega',
    number: 1,
    link: '/checkout/location',
  },
  payment: {
    name: 'Pago',
    number: 2,
    link: '/checkout/payment',
  },
  confirmation: {
    name: 'Confirmar',
    number: 3,
    link: '/checkout/confirm',
  },
};

export const DAYS = {
  mon: 'Lunes',
  tue: 'Martes',
  wed: 'Miercoles',
  thu: 'Jueves',
  fri: 'Viernes',
  sat: 'Sabado',
  sun: 'Domingo',
};

export const MONTH_NAMES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Dicembre',
];

export const DAYS_ORDER = {
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 7,
};

export const PAYMENT_METHODS = {
  cashOnDelivery: {
    type: 'Cash On Delivery',
    label: 'Pago en efectivo',
  },
  card: {
    type: 'Stripe',
    label: 'Stripe',
  },
  paypal: {
    type: 'Paypal',
    label: 'Paypal',
  },
  stripe: {
    type: 'Stripe',
    label: 'Stripe',
  },
};

export const SHIPPING_COST = 100;

export const ORDER_STATUS = {
  Processing: {
    label: 'Fecha de entrega',
    info: 'En proceso',
  },
  Delivered: {
    label: 'Entregado el',
    info: 'Entregado',
  },
};
