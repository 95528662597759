import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Header from '../../components/Layout/Header/Header';
import ShopInfo from '../../components/Shop/ShopInfo';
import ShopProfileData from '../../components/Shop/ShopProfileData';
import { API_URL_V2 } from '../../server';
import styles from '../../styles/styles';

const ShopPreviewPage = () => {
  const [data, setData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    axios.get(`${API_URL_V2}/shop/get-shop-info/${id}`).then((res) => {
      setData(res.data.shop);
    });
  }, []);

  return (
    <>
      <Header activeHeading={3} />
      <div className={`${styles.section} mx-auto my-0 max-w-[80rem]`}>
        <div className="w-full">
          <div className=" rounded-[4px] border-b-2 bg-white shadow-sm">
            <ShopInfo isOwner={false} data={data} />
          </div>
          <div className="mt-5 rounded-[4px] 800px:mt-['unset'] 800px:w-full">
            <ShopProfileData isOwner={false} shopDetails={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopPreviewPage;
