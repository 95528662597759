import axios, { AxiosResponse } from 'axios';
import { API_URL_V2 } from '../../server';
import { AppConfiguration, AppConfigurationResult } from '../../interfaces/config';

export const getConfigService = async () => {
  try {
    const result: AxiosResponse<AppConfigurationResult> = await axios.get(`${API_URL_V2}/configuration/get-config`);

    return {
      success: result.data.success,
      data: result.data.siteConfig,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Error al obtener configuración',
    };
  }
};
