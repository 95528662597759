import Lottie from 'react-lottie';
import animationData from '../Assests/animations/warning-animation.json';
import CustomerCommonLayout from '../components/Layout/CustomerCommonLayout';
import { Link } from 'react-router-dom';

const OrderCancelPage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  return (
    <>
      <CustomerCommonLayout>
        <Lottie options={defaultOptions} width={300} height={300} />
        <h5 className="mb-14 text-center text-3xl text-gray-950">Oops, has cancelado tu orden y no hemos podido procesar tu pago</h5>
        <Link className="text-center" to="/">
          <p className="text-xl text-primary-500">Ir a inicio</p>
        </Link>
        <br />
      </CustomerCommonLayout>
    </>
  );
};

export default OrderCancelPage;
