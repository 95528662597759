import axios from 'axios';
import { API_URL_V2, API_URL_V3 } from '../../server';

export const createOrderWithCashOnDelivery = async (order) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
    const { data } = await axios.post(`${API_URL_V2}/order/create-order`, order, config);
    return { data };
  } catch (error) {
    console.error(error);
    return {
      error: error.response?.data?.message,
    };
  }
};

// stripe
export const createStripeSession = async (order) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
    const stripePayload = {
      ...order,
      cancel_url: `${process.env.REACT_APP_DOMAIN}/order/cancel`,
      success_url: `${process.env.REACT_APP_DOMAIN}/order/success`,
    };
    const { data } = await axios.post(`${API_URL_V3}/order/create-order-session`, stripePayload, config);
    return {
      data,
    };
  } catch (error) {
    console.error(error);
    return {
      error: error.response?.data?.message,
    };
  }
};
