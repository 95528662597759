import { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { CiLocationOn } from 'react-icons/ci';
import { FaRegTrashCan } from 'react-icons/fa6';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux.hooks';
import { deleteUserAddress } from '../../../../redux/actions/user';
import MKCard from '../../../UI/MKCard';
import MKButton from '../../../UI/MKButton';
import EditAddress from './EditAddress/EditAddress';
import styles from '../../../../styles/styles';

const CustomerAddressList = () => {
  const [open, setOpen] = useState(false);
  const [addressDetails, setAddressDetails] = useState(null);
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const handleDelete = (e, item) => {
    e.stopPropagation();
    const id = item._id;
    dispatch(deleteUserAddress(id));
  };
  const handleClose = () => {
    setAddressDetails(null);
    setOpen(false);
  };
  const enableEdit = (address) => {
    setAddressDetails(address);
    setOpen(true);
  };

  return (
    <div className="w-full px-5">
      {open && <EditAddress handleClose={handleClose} addressDetails={addressDetails} />}
      <div className="flex w-full items-center justify-between">
        <h1 className=" pb-2 text-lg text-gray-800 md:text-2xl">Mis direcciones de entrega</h1>
        <div className={`${styles.button} flex flex-row gap-2 md:gap-4`} onClick={() => setOpen(true)}>
          <MdAdd className="h-6 w-6" />
          <span className="text-white">Agregar</span>
        </div>
      </div>

      {user &&
        user.addresses.map((item, index) => (
          <section className="mb-5 flex items-center focus:border-primary-100">
            <MKButton
              onClick={() => enableEdit(item)}
              className="w-full rounded-md bg-white p-0 hover:bg-primary-100 focus:border-primary-100"
            >
              <MKCard className="flex w-full items-center justify-between rounded-md" key={index}>
                <section className="flex items-center gap-4">
                  <CiLocationOn className="text-2xl" />
                  <section>
                    <div className="flex items-center">
                      <h5 className="font-light ">{item.addressType}</h5>
                    </div>
                    <div className="flex items-center">
                      <h6 className="text-xs text-gray-400">
                        {item.address1} {item.address2}
                      </h6>
                    </div>
                    <div className="flex items-center">
                      <h6 className="text-[12px] 800px:text-[unset]">{user && user.phoneNumber}</h6>
                    </div>
                  </section>
                </section>
              </MKCard>
            </MKButton>
            <div className="flex min-w-[10%] items-center justify-between pl-8">
              <MKButton variant="text" className="p-1" onClick={(e) => handleDelete(e, item)}>
                <FaRegTrashCan className="h-4 w-4 cursor-pointer hover:text-red-600 md:h-6 md:w-6" />
              </MKButton>
            </div>
          </section>
        ))}

      {user && user.addresses.length === 0 && <h5 className="pt-8 text-lg">No has agregado ninguna dirección!</h5>}
    </div>
  );
};

export default CustomerAddressList;
