/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { BsShop } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { MdOutlineHideImage } from 'react-icons/md';

import MKCard from '../../UI/MKCard';
import FilterOrders from '../../Orders/FilterOrders';
import EmptyOrders from '../../Orders/EmptyOrders';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks';
import { getAllOrdersOfUser } from '../../../redux/actions/order';
import { ORDER_STATUS } from '../../../utils/constants/literalStrings';

const CustomerAllOrders = () => {
  const { user } = useAppSelector((state) => state.user);
  const { orders, isLoading } = useAppSelector((state) => state.order);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const PRODUCT_IMAGES_TO_SHOW = 3;
  return (
    <div className="w-full px-4 pl-8 pt-1">
      <section className="flex flex-col gap-4 md:flex-row ">
        {/* {Array.isArray(orders) && orders.length > 0 && <FilterOrders />} */}
        <section className="flex w-full flex-col gap-2">
          {orders &&
            Array.isArray(orders) &&
            orders.map((order) => (
              <MKCard key={`sc-order-${order._id}`}>
                <div className="flex justify-between">
                  <div className="mb-2 flex items-center">
                    {order.shop?.avatar?.url ? (
                      <img className="h-12 w-12 rounded-sm border" src={order.shop?.avatar?.url} alt="logo" />
                    ) : (
                      <div className="rounded-sm border p-2">
                        <BsShop className="h-10 w-10" />
                      </div>
                    )}
                    <p className="ml-2">{order?.shop?.name}</p>
                  </div>
                  <div>
                    <p className="rounded-sm border bg-primary-50 p-2 text-sm font-bold text-primary-500">
                      {ORDER_STATUS[order.status]?.info}
                    </p>
                  </div>
                </div>
                <p className="text-sm font-bold text-primary-200">{ORDER_STATUS[order.status]?.label}</p>
                <p>
                  {order?.deliveryDate &&
                    new Intl.DateTimeFormat('es', { weekday: 'long', day: '2-digit', month: 'short', year: '2-digit' }).format(
                      new Date(order?.deliveryDate)
                    )}
                </p>
                <section>
                  {Array.isArray(order?.cart) && (
                    <div className="flex flex-row gap-4">
                      {[...order.cart.slice(0, PRODUCT_IMAGES_TO_SHOW)].map((product) => (
                        <div className="rounded-full border p-3">
                          {Array.isArray(product.images) && product.images.length > 0 ? (
                            <img className="h-12 w-12 object-contain" src={product.images[0].url} alt={product.name} />
                          ) : (
                            <MdOutlineHideImage className="h-12 w-12 text-gray-300" />
                          )}
                        </div>
                      ))}
                      {order.cart.length >= PRODUCT_IMAGES_TO_SHOW + 1 && (
                        <div className=" rounded-full border p-3">
                          <div className="flex h-12 w-12 items-center justify-center text-center">
                            +{order.cart.length - PRODUCT_IMAGES_TO_SHOW}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </section>
                <div className="mt-4 flex flex-row items-center">
                  <div>
                    <p>Total: C$ {order.totalPrice.toFixed(2)}</p>
                  </div>
                </div>
              </MKCard>
            ))}
        </section>
      </section>
      {!isLoading && orders && orders?.length === 0 && (
        <section className="mb-12 flex flex-col items-center justify-center">
          <EmptyOrders />
          <p className="my-4">Aun no has hecho tu primera compra</p>
          <Link className="rounded-xl border border-primary-200 p-2 text-primary-200" to="/">
            Hazla ahora
          </Link>
        </section>
      )}
    </div>
  );
};

export default CustomerAllOrders;
