import { useState } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { APP_CONFIG } from '../../../../utils/constants/common';
import MKDrawer from '../../../UI/MKDrawer';
import MKOTP from '../../../UI/MKOtp';
import MKButton from '../../../UI/MKButton';
import { VERIFY_OTP_CODE } from '../../../../services/auth/login';
import { useNavigate } from 'react-router-dom';
import { loadUser } from '../../../../redux/actions/user';
import { useAppDispatch } from '../../../../hooks/redux.hooks';
import { IoClose } from 'react-icons/io5';

interface ICodeVerificationDrawerProps {
  phoneNumber: string;
  handleCloseDrawer: () => void;
}
const CodeVerificationDrawer = ({ phoneNumber, handleCloseDrawer }: ICodeVerificationDrawerProps) => {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const dispatch = useAppDispatch();

  const handleVerifyPhoneNumber = async () => {
    if (code.length !== 6) {
      return;
    }
    const codeVerificationResponse = await VERIFY_OTP_CODE(code, phoneNumber);

    if (codeVerificationResponse?.error) {
      toast.error('Código inválido');
      return;
    }

    if (!codeVerificationResponse || !codeVerificationResponse?.token) {
      toast.error('Ha ocurrido un error');
      return;
    }
    Cookies.set('token', `${codeVerificationResponse.token}`);
    toast.success('Login Success!');
    navigate('/');
    dispatch(loadUser());
  };
  return (
    <MKDrawer>
      <section className="flex flex-col px-3 py-6">
        <button className="absolute right-4 top-1" onClick={handleCloseDrawer}>
          <IoClose className="h-8 w-8 hover:text-primary-500" />
        </button>
        <h3 className="text-2xl">Ingresa el código</h3>
        <p className="pb-4 pt-2">
          Enviamos un código a{' '}
          <span className="text-primary-200">
            {APP_CONFIG.countryCode} {phoneNumber}
          </span>{' '}
          para validar tu identidad.
        </p>
        <MKOTP code={code} setCode={setCode} />
        <MKButton onClick={handleVerifyPhoneNumber} className="my-8 w-full self-center md:w-2/4">
          <p className="text-center text-white">Verificar código</p>
        </MKButton>
      </section>
    </MKDrawer>
  );
};

export default CodeVerificationDrawer;
