import { useEffect, useRef, useState } from 'react';
import Header from '../components/Layout/Header/Header';
import { useSelector } from 'react-redux';
import { API_URL_V2 } from '../server';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowRight, AiOutlineSend } from 'react-icons/ai';
import { TfiGallery } from 'react-icons/tfi';
import styles from '../styles/styles';
import { getTimeAgo } from '../utils/datetime/time';

const UserInbox = () => {
  const { user, loading } = useSelector((state) => state.user);
  const [conversations, setConversations] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [currentChat, setCurrentChat] = useState();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userData, setUserData] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [images, setImages] = useState();
  const [activeStatus, setActiveStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    arrivalMessage && currentChat?.members.includes(arrivalMessage.sender) && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    const getConversation = async () => {
      try {
        const resonse = await axios.get(`${API_URL_V2}/conversation/get-all-conversation-user/${user?._id}`, {
          withCredentials: true,
        });

        setConversations(resonse.data.conversations);
      } catch (error) {}
    };
    getConversation();
  }, [user, messages]);

  const onlineCheck = (chat) => {
    const chatMembers = chat.members.find((member) => member !== user?._id);
    const online = onlineUsers.find((user) => user.userId === chatMembers);

    return online ? true : false;
  };

  // get messages
  useEffect(() => {
    const getMessage = async () => {
      try {
        const response = await axios.get(`${API_URL_V2}/message/get-all-messages/${currentChat?._id}`);
        setMessages(response.data.messages);
      } catch (error) {}
    };
    getMessage();
  }, [currentChat]);

  // create new message
  const sendMessageHandler = async (e) => {
    e.preventDefault();

    const message = {
      sender: user._id,
      text: newMessage,
      conversationId: currentChat._id,
    };
    try {
      if (newMessage !== '') {
        await axios
          .post(`${API_URL_V2}/message/create-new-message`, message)
          .then((res) => {
            setMessages([...messages, res.data.message]);
            updateLastMessage();
          })
          .catch((error) => {});
      }
    } catch (error) {}
  };

  const updateLastMessage = async () => {
    await axios
      .put(`${API_URL_V2}/conversation/update-last-message/${currentChat._id}`, {
        lastMessage: newMessage,
        lastMessageId: user._id,
      })
      .then((res) => {
        setNewMessage('');
      })
      .catch((error) => {});
  };

  const handleImageUpload = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImages(reader.result);
        imageSendingHandler(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const imageSendingHandler = async (e) => {
    try {
      await axios
        .post(`${API_URL_V2}/message/create-new-message`, {
          images: e,
          sender: user._id,
          text: newMessage,
          conversationId: currentChat._id,
        })
        .then((res) => {
          setImages();
          setMessages([...messages, res.data.message]);
          updateLastMessageForImage();
        });
    } catch (error) {}
  };

  const updateLastMessageForImage = async () => {
    await axios.put(`${API_URL_V2}/conversation/update-last-message/${currentChat._id}`, {
      lastMessage: 'Photo',
      lastMessageId: user._id,
    });
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ beahaviour: 'smooth' });
  }, [messages]);

  return (
    <div className="w-full">
      {!open && (
        <>
          <Header />
          <h1 className="py-3 text-center text-[30px]">All Messages</h1>
          {/* All messages list */}
          {conversations &&
            conversations.map((item, index) => (
              <MessageList
                data={item}
                key={index}
                index={index}
                setOpen={setOpen}
                setCurrentChat={setCurrentChat}
                me={user?._id}
                setUserData={setUserData}
                userData={userData}
                online={onlineCheck(item)}
                setActiveStatus={setActiveStatus}
                loading={loading}
              />
            ))}
        </>
      )}

      {open && (
        <SellerInbox
          setOpen={setOpen}
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          sendMessageHandler={sendMessageHandler}
          messages={messages}
          sellerId={user._id}
          userData={userData}
          activeStatus={activeStatus}
          scrollRef={scrollRef}
          handleImageUpload={handleImageUpload}
        />
      )}
    </div>
  );
};

const MessageList = ({ data, index, setOpen, setCurrentChat, me, setUserData, userData, online, setActiveStatus, loading }) => {
  const [active, setActive] = useState(0);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/inbox?${id}`);
    setOpen(true);
  };

  useEffect(() => {
    setActiveStatus(online);
    const userId = data.members.find((user) => user !== me);
    const getUser = async () => {
      try {
        const res = await axios.get(`${API_URL_V2}/shop/get-shop-info/${userId}`);
        setUser(res.data.shop);
      } catch (error) {}
    };
    getUser();
  }, [me, data]);

  return (
    <div
      className={`flex w-full p-3 px-3 ${active === index ? 'bg-[#00000010]' : 'bg-transparent'}  cursor-pointer`}
      onClick={(e) => setActive(index) || handleClick(data._id) || setCurrentChat(data) || setUserData(user) || setActiveStatus(online)}
    >
      <div className="relative">
        <img src={`${user?.avatar?.url}`} alt="" className="h-[50px] w-[50px] rounded-full" />
        {online ? (
          <div className="absolute right-[2px] top-[2px] h-[12px] w-[12px] rounded-full bg-green-400" />
        ) : (
          <div className="absolute right-[2px] top-[2px] h-[12px] w-[12px] rounded-full bg-[#c7b9b9]" />
        )}
      </div>
      <div className="pl-3">
        <h1 className="text-[18px]">{user?.name}</h1>
        <p className="text-[16px] text-[#000c]">
          {!loading && data?.lastMessageId !== userData?._id ? 'You:' : userData?.name.split(' ')[0] + ': '} {data?.lastMessage}
        </p>
      </div>
    </div>
  );
};

const SellerInbox = ({
  setOpen,
  newMessage,
  setNewMessage,
  sendMessageHandler,
  messages,
  sellerId,
  userData,
  activeStatus,
  scrollRef,
  handleImageUpload,
}) => {
  return (
    <div className="flex min-h-full w-[full] flex-col justify-between p-5">
      {/* message header */}
      <div className="flex w-full items-center justify-between bg-slate-200 p-3">
        <div className="flex">
          <img src={`${userData?.avatar?.url}`} alt="" className="h-[60px] w-[60px] rounded-full" />
          <div className="pl-3">
            <h1 className="text-[18px] font-[600]">{userData?.name}</h1>
            <h1>{activeStatus ? 'Active Now' : ''}</h1>
          </div>
        </div>
        <AiOutlineArrowRight size={20} className="cursor-pointer" onClick={() => setOpen(false)} />
      </div>

      {/* messages */}
      <div className="h-[75vh] overflow-y-scroll px-3 py-3">
        {messages &&
          messages.map((item, index) => (
            <div className={`my-2 flex w-full ${item.sender === sellerId ? 'justify-end' : 'justify-start'}`} ref={scrollRef}>
              {item.sender !== sellerId && <img src={`${userData?.avatar?.url}`} className="mr-3 h-[40px] w-[40px] rounded-full" alt="" />}
              {item.images && <img src={`${item.images?.url}`} className="mb-2 ml-2 h-[300px] w-[300px] rounded-[10px] object-cover" />}
              {item.text !== '' && (
                <div>
                  <div className={`w-max rounded p-2 ${item.sender === sellerId ? 'bg-[#000]' : 'bg-[#38c776]'} h-min text-[#fff]`}>
                    <p>{item.text}</p>
                  </div>

                  <p className="pt-1 text-[12px] text-[#000000d3]">{getTimeAgo(item.createdAt)}</p>
                </div>
              )}
            </div>
          ))}
      </div>

      {/* send message input */}
      <form aria-required={true} className="relative flex w-full items-center justify-between p-3" onSubmit={sendMessageHandler}>
        <div className="w-[30px]">
          <input type="file" name="" id="image" className="hidden" onChange={handleImageUpload} />
          <label htmlFor="image">
            <TfiGallery className="cursor-pointer" size={20} />
          </label>
        </div>
        <div className="w-full">
          <input
            type="text"
            required
            placeholder="Enter your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className={`${styles.input}`}
          />
          <input type="submit" value="Send" className="hidden" id="send" />
          <label htmlFor="send">
            <AiOutlineSend size={20} className="absolute right-4 top-5 cursor-pointer" />
          </label>
        </div>
      </form>
    </div>
  );
};

export default UserInbox;
