import { useState } from 'react';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FaCheck } from 'react-icons/fa';

import CardMethod from '../CardMethod';
import MKCard from '../../../UI/MKCard';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux.hooks';
import { setCheckoutInfo } from '../../../../redux/slices/checkoutSlice';
import { PAYMENT_METHODS } from '../../../../utils/constants/literalStrings';
import styles from '../../../../styles/styles';

interface IPaymentInfo {
  handleNextStep: () => void;
}
const PaymentInfo = ({ handleNextStep }: IPaymentInfo) => {
  const [select, setSelect] = useState(1);
  const { checkoutForms } = useAppSelector((state) => state.checkout);
  const dispatch = useAppDispatch();
  const stripeApikey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  const nextStep = () => {
    const payment = Object.keys(PAYMENT_METHODS)[select - 1];

    dispatch(setCheckoutInfo({ ...checkoutForms, paymentInfo: { type: PAYMENT_METHODS[payment] } }));

    handleNextStep();
  };

  return (
    <section className="flex w-full flex-col">
      <div className="w-full rounded-md bg-[#fff] p-5 pb-8 800px:w-[95%]">
        {/* select buttons */}

        {/* cash on delivery */}
        <MKCard className="p-0">
          <button className="flex w-full items-center p-4" onClick={() => setSelect(1)}>
            {select === 1 ? (
              <div className="mr-4 flex items-center justify-center rounded-full bg-gray-200 p-2">
                <FaCheck className="h-3 w-3 text-primary-200" />
              </div>
            ) : (
              <div className="mr-4 h-6 w-6 rounded-full bg-gray-200 p-2" />
            )}
            <div>
              <div className="mb-2 flex w-full">
                <h4 className="pl-2 text-[18px] font-[600] text-[#000000b1]">Pagar cuando reciba</h4>
              </div>
            </div>
          </button>
        </MKCard>
        <Elements stripe={loadStripe(stripeApikey)}>
          <MKCard className="mt-4 p-0">
            <button onClick={() => setSelect(2)} className="flex w-full items-center p-4">
              {select === 2 ? (
                <div className="mr-4 flex items-center justify-center rounded-full bg-gray-200 p-2">
                  <FaCheck className="h-3 w-3 text-primary-200" />
                </div>
              ) : (
                <div className="mr-4 h-6 w-6 rounded-full bg-gray-200 p-2" />
              )}
              <div>
                <div className="mb-2 flex w-full">
                  <h4 className="text-gr pl-2 text-[18px] font-[600]">Pagar con Stripe</h4>
                </div>
              </div>
            </button>
          </MKCard>

          {/* paypal payment */}
          {/*           
          <MKCard className="mt-4 p-0">
            <button className="flex w-full items-center p-4" onClick={() => setSelect(3)}>
              {select === 3 ? (
                <div className="mr-4 flex items-center justify-center rounded-full bg-gray-200 p-2">
                  <FaCheck className="h-3 w-3 text-primary-200" />
                </div>
              ) : (
                <div className="mr-4  h-6 w-6 rounded-full bg-gray-200 p-2" />
              )}
              <div className=" mb-2 flex w-full pb-2">
                <h4 className=" text-[18px] font-[600] text-[#000000b1]">Paypal</h4>
              </div>
            </button>

            {select === 3 ? <PaypalMethod /> : null}
          </MKCard> */}

          <br />
        </Elements>
      </div>
      <button className={`${styles.button} self-center`} onClick={nextStep}>
        Siguiente
      </button>
    </section>
  );
};

export default PaymentInfo;
