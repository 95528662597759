import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdOutlineHideImage } from 'react-icons/md';
import { IoCalendarOutline, IoLocationOutline } from 'react-icons/io5';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FaCheck, FaRegClock } from 'react-icons/fa';

import styles from '../../../../styles/styles';
import MKCard from '../../../UI/MKCard';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux.hooks';
import { PAYMENT_METHODS } from '../../../../utils/constants/literalStrings';
import { isObjectEmpty } from '../../../../utils/object';
import { clearShopCart } from '../../../../redux/actions/cart';
import useMerkolineConfig from '../../../../hooks/configuration/useMerkolineConfig';
import StripePaymentPethod from '../../PaymentStep/StripeMethod';
import { createOrderWithCashOnDelivery } from '../../../../services/orders/createOrders';

const OrderSummary = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { config } = useMerkolineConfig();
  const [comment, setComment] = useState('');
  const {
    checkout: { checkoutForms },
    cart,
    user,
  } = useAppSelector((state) => state);
  const currentCheckoutShop = cart.cart[id];

  const productsInCart = Array.isArray(currentCheckoutShop?.products) ? currentCheckoutShop?.products : null;
  const stripeApikey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  const subTotalPrice = productsInCart ? productsInCart.reduce((acc, item) => acc + item.quantity * item.price, 0) : 0;
  const totalPrice = (subTotalPrice + config?.shipping_price).toFixed(2);
  const deliveryDate = new Date(checkoutForms.deliveryDate);
  const formattedDate = new Intl.DateTimeFormat('es', { weekday: 'long', day: '2-digit', month: 'short', year: 'numeric' }).format(
    deliveryDate
  );

  const setOrderPayload = () => {
    const currentShopCart = typeof cart?.cart === 'object' ? cart?.cart[id] : {};
    if (isObjectEmpty(currentShopCart.products)) {
      return;
    }
    return {
      cart: currentShopCart.products,
      shop: currentShopCart.shop,
      shippingAddress: checkoutForms.shippingAddress,
      user: user && user.user,
      totalPrice: Number(totalPrice),
      paymentInfo: {
        type: PAYMENT_METHODS.cashOnDelivery.type,
      },
      comment,
      deliveryDate: new Date(checkoutForms.deliveryDate),
      deliveryTime: checkoutForms.deliveryTime,
    };
  };

  const handleCreateOrder = async () => {
    try {
      const order = setOrderPayload();

      if (!order) {
        toast.error('No se pudo crear la orden');
        return;
      }

      const { data, error } = await createOrderWithCashOnDelivery(order);

      if (error || !data) {
        toast.error(error || 'Error al crear la orden');
        return;
      }
      dispatch(clearShopCart(id));
      navigate('/order/success');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <section className="mx-auto my-0 flex max-w-2xl flex-col justify-center bg-white p-4 md:rounded-xl md:border md:border-gray-200 md:shadow-lg">
      <MKCard className=" w-full">
        <p className="text-center text-lg font-bold">Resúmen de orden</p>
        <section className="mx-auto my-0 md:max-w-xs">
          <div className="flex flex-row justify-between">
            <p>Subtotlal</p>
            <p>C$ {subTotalPrice.toFixed(2)}</p>
          </div>
          <div className="flex flex-row justify-between">
            <p>Costo de envio</p>
            <p>C$ {config?.shipping_price}</p>
          </div>
          <div className="mt-2 flex flex-row justify-between rounded-lg bg-primary-100 p-2 text-base font-bold">
            <p>Total</p>
            <p>C$ {totalPrice}</p>
          </div>
        </section>
      </MKCard>
      <section className="my-4">
        <p className="">Establecimiento</p>
        <div className="m-4 flex flex-row justify-between rounded-lg bg-primary-100 p-2 text-base font-bold">
          <p className="py-2">{currentCheckoutShop?.shop?.name}</p>
        </div>
      </section>

      <section>
        <p className="">Entrega</p>
        <section
          className="h-26 m-4 mt-2 flex items-center rounded-xl border-2 border-slate-200
          p-2"
        >
          <IoLocationOutline className=" h-6 w-6" />
          <div className="w-36">
            <p className="text-sm">{checkoutForms.shippingAddress.address1}</p>
            <p className="line-clamp-2 pt-2 text-sm text-gray-500">{checkoutForms.shippingAddress.address2}</p>
          </div>
        </section>
      </section>

      <section>
        <p className="">Fecha y hora</p>
        <div className="flex justify-between">
          <section
            className="h-26 m-4 mt-2 flex w-max items-center rounded-xl border-2 border-slate-200
          p-2"
          >
            <IoCalendarOutline className="mr-2 h-6 w-6" />
            <div className="w-36">
              <p className="text-sm">{formattedDate}</p>
            </div>
          </section>
          <section
            className="h-26 m-4 mt-2 flex w-max items-center rounded-xl border-2 border-slate-200
          p-2"
          >
            <FaRegClock className="mr-2 h-6 w-6" />
            <div className="w-36">
              <p className="text-sm">{checkoutForms.deliveryTime.intervalDescription}</p>
            </div>
          </section>
        </div>
      </section>

      <section>
        <p className="">Método de pago</p>
        <section
          className="h-26 m-4 mt-2 flex items-center rounded-xl border-2 border-slate-200
          p-2"
        >
          <div className="mr-4 flex items-center justify-center rounded-full bg-primary-200 p-2">
            <FaCheck className="h-3 w-3 text-white" />
          </div>
          <div className="w-36">
            <p className="text-sm">{checkoutForms.paymentInfo.type.label}</p>
          </div>
        </section>
      </section>

      <section>
        <label htmlFor="email" className="block">
          Comentarios
        </label>
        <div className="mt-1 px-4">
          <input
            type="text"
            name="comment"
            placeholder="Agrega un commentario para la tienda"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
          />
        </div>
      </section>

      <section className="my-4">
        <p>Productos</p>
        <MKCard className="my-4 flex flex-col items-center justify-center border-none bg-gray-100">
          <div className="flex items-center justify-between md:w-5/6">
            <div className=" w-12"></div>
            <p className="w-2/4 text-center">Nombre</p>
            <p className="w-2/12 text-center">Cantidad</p>
            <p className="w-2/12 text-end">Precio</p>
          </div>
          {productsInCart &&
            productsInCart.map((product) => (
              <div className="flex items-center justify-between md:w-5/6">
                {product?.images[0]?.url ? (
                  <img className="h-14 w-12 object-contain" src={product.images[0].url} alt={product.name} />
                ) : (
                  <MdOutlineHideImage className="h-14 w-12 text-gray-300" />
                )}
                <p className="line-clamp-1 w-2/4 text-center">{product.name}</p>
                <p className="w-2/12 text-center">{product.quantity}</p>
                <p className="w-2/12 text-end">C${Number(product.price * product.quantity).toFixed(2)}</p>
              </div>
            ))}
        </MKCard>
      </section>
      {checkoutForms.paymentInfo.type.label === PAYMENT_METHODS.stripe.type ? (
        <>
          <Elements stripe={loadStripe(stripeApikey)}>
            <StripePaymentPethod totalPrice={totalPrice} setOrderPayload={setOrderPayload} />
          </Elements>
        </>
      ) : (
        <button onClick={handleCreateOrder} className={`${styles.button} w-full self-center`}>
          Realizar pedido C${totalPrice}
        </button>
      )}
    </section>
  );
};

export default OrderSummary;
