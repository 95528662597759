import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { MdOutlineHideImage } from 'react-icons/md';
import { IoIosArrowForward } from 'react-icons/io';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pagination } from 'swiper/modules';

import { IShop } from '../../../interfaces/shop';
import styles from '../../../styles/styles';
import ProductCard from '../../Route/ProductCard/ProductCard';

interface ISingleShopProducts {
  shopProducts: IShop[];
}

const SingleShopProducts = ({ shopProducts }: ISingleShopProducts) => {
  const shopSkeleton = new Array(4).fill(0);
  return (
    <>
      {shopProducts.map(({ _id, name, avatar, products }) => (
        <section key={`sp-${_id}`} className={`${styles.section} pb-8`}>
          <Link to={`/shop/${_id}`} className="flex items-center pb-4">
            {avatar !== null && typeof avatar === 'object' && avatar.url ? (
              <img src={avatar?.url} alt="" srcSet="" className="h-[50px] w-[50px] rounded-xl object-cover" />
            ) : (
              <MdOutlineHideImage className="h-[50px] w-[50px] rounded-xl text-gray-400" />
            )}
            <h1 className=" ml-2 font-semibold">{name}</h1>
            <div className="ml-4 inline-block md:ml-8">
              <IoIosArrowForward className="h-4 w-4  text-[#000]" />
            </div>
          </Link>
          <section className="px-6">
            {products && (
              <>
                <Swiper
                  pagination={{
                    clickable: true,
                  }}
                  spaceBetween={20}
                  breakpoints={{
                    640: {
                      slidesPerView: 'auto',
                      spaceBetween: 20,
                    },
                    780: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    800: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                  }}
                  modules={[Pagination]}
                >
                  {products.map((product) => (
                    <>
                      <SwiperSlide key={`sp-product${product._id}`} className="product_card">
                        <ProductCard data={product} shopId={_id} />
                      </SwiperSlide>
                    </>
                  ))}
                </Swiper>
              </>
            )}
          </section>
        </section>
      ))}

      {(!shopProducts || (Array.isArray(shopProducts) && shopProducts.length === 0)) && (
        <section className="w-full px-8">
          {shopSkeleton.map((_, index) => (
            <div key={`skeleton-shop-products-${index}`} className={`w-full pb-8`}>
              <div className=" w-full p-4">
                <div className="flex animate-pulse flex-col space-x-4 space-y-4">
                  <div className="h-10 w-1/2 rounded-md bg-slate-200" />
                  <div className="flex flex-row flex-wrap gap-4 py-1">
                    <div className="h-60 w-60 rounded bg-slate-200"></div>
                    <div className="h-60 w-60 rounded bg-slate-200"></div>
                    <div className="h-60 w-60 rounded bg-slate-200"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      )}
    </>
  );
};

export default SingleShopProducts;
