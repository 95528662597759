import axios from 'axios';
import { API_URL_V2 } from '../../server';

// get all orders of user
export const getAllOrdersOfUser = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllOrdersUserRequest',
    });

    const { data } = await axios.get(`${API_URL_V2}/order/get-all-orders/${userId}`, {
      withCredentials: true,
    });

    dispatch({
      type: 'getAllOrdersUserSuccess',
      payload: data.orders,
    });
  } catch (error) {
    dispatch({
      type: 'getAllOrdersUserFailed',
      payload: error.response.data.message,
    });
  }
};

// get all orders of seller
export const getAllOrdersOfShop = (shopId) => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllOrdersShopRequest',
    });

    const { data } = await axios.get(`${API_URL_V2}/order/get-seller-all-orders/${shopId}`);

    dispatch({
      type: 'getAllOrdersShopSuccess',
      payload: data.orders,
    });
  } catch (error) {
    dispatch({
      type: 'getAllOrdersShopFailed',
      payload: error.response.data.message,
    });
  }
};

// get all orders of Admin
export const getAllOrdersOfAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: 'adminAllOrdersRequest',
    });

    const { data } = await axios.get(`${API_URL_V2}/order/admin-all-orders`, {
      withCredentials: true,
    });

    dispatch({
      type: 'adminAllOrdersSuccess',
      payload: data.orders,
    });
  } catch (error) {
    dispatch({
      type: 'adminAllOrdersFailed',
      payload: error.response.data.message,
    });
  }
};
