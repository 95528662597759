import axios from 'axios';
import { API_URL_V2 } from '../../server';
import { CONFIGURATION_ACTION_NAMES } from '../../types/redux';
import { GET_COUNTRY_SERVICE } from '../../services/configuration/country';
import { ICityResponse, IRegionsAndCity } from '../../interfaces/redux';

export const getCountry = () => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGURATION_ACTION_NAMES.setLoadingConfigAction,
      payload: true,
    });
    const countryResponse = await GET_COUNTRY_SERVICE();

    dispatch({
      type: CONFIGURATION_ACTION_NAMES.setLoadingConfigAction,
      payload: false,
    });
    dispatch({
      type: CONFIGURATION_ACTION_NAMES.setCountrySuccess,
      payload: countryResponse.country_name,
    });
  } catch (error) {
    dispatch({
      type: CONFIGURATION_ACTION_NAMES.setErrorConfigAction,
      payload: true,
    });
  }
};

// get country and regions
export const getCountryAndAllRegions = () => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGURATION_ACTION_NAMES.setLoadingRegion,
      payload: true,
    });

    const { data } = await axios.get(`${API_URL_V2}/region/get-regions-and-cities`);
    dispatch({
      type: CONFIGURATION_ACTION_NAMES.setLoadingRegion,
      payload: true,
    });
    dispatch({
      type: CONFIGURATION_ACTION_NAMES.setRegionAction,
      payload: data.regions,
    });
  } catch (error) {
    dispatch({
      type: CONFIGURATION_ACTION_NAMES.setLoadingRegion,
      payload: false,
    });
  }
};

export const setSelectedRegion = (region: IRegionsAndCity) => (dispatch) => {
  dispatch({
    type: CONFIGURATION_ACTION_NAMES.setSelectedRegion,
    payload: region,
  });
};

export const setSelectedCity = (city: ICityResponse) => (dispatch) => {
  dispatch({
    type: CONFIGURATION_ACTION_NAMES.setSelectedCity,
    payload: city,
  });
};
