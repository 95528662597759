import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import MKButton from '../../../UI/MKButton';
import MKOTP from '../../../UI/MKOtp';
import { useAppDispatch } from '../../../../hooks/redux.hooks';
import { authWithEmailAndOtp } from '../../../../services/auth/login';
import { loadUser } from '../../../../redux/actions/user';
import CompleteSignUp from '../CompleteSignUp';
import SendCodeAgain from './sendCodeAgain';
interface IOtpVerificationDrawerProps {
  email: string;
}
const OtpVerificationDrawer = ({ email }: IOtpVerificationDrawerProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showCompleteSignUp, setShowCompleteSignUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const authenticationMethod = 'email';
  const [registerToken, setRegisterToken] = useState('');

  const [code, setCode] = useState('');
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!code || code.length !== 6 || isLoading) {
      return;
    }

    setIsLoading(true);
    const response = await authWithEmailAndOtp(email, code);
    setIsLoading(false);
    if (response.success && response.token && response.isNewUser) {
      // show drawer to complete user information
      setRegisterToken(response.token);
      setShowCompleteSignUp(true);
      return;
    }
    if (response.success && response.token && response.user) {
      Cookies.set('token', `${response.token}`);
      dispatch(loadUser());
      navigate('/');
      return;
    }
    toast.error(response?.message || 'Error al completar el registro');
  };
  return (
    <section className="flex flex-col gap-4">
      {showCompleteSignUp ? (
        <CompleteSignUp registerToken={registerToken} authenticationMethod={authenticationMethod} />
      ) : (
        <>
          <div>
            <p className=" text-2xl font-bold text-gray-600">Ingresa el código</p>
            <p className="text-gray-700">Enviamos un código a {email} para validar tu identidad.</p>
          </div>
          <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
            <div>
              <MKOTP code={code} setCode={setCode} />
            </div>
            <MKButton type="submit" className="w-full text-white">
              Continuar
            </MKButton>
          </form>
          <SendCodeAgain email={email} />
        </>
      )}
    </section>
  );
};

export default OtpVerificationDrawer;
