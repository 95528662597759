import axios from 'axios';
import { useEffect, useState } from 'react';
import { API_URL_V2 } from '../../server';

const useDeliveryDate = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getSchedules = async () => {
      try {
        const { data } = await axios.get(`${API_URL_V2}/delivery-schedule/get-all`);
        if (data) {
          setData(data);
          setLoading(false);
        }
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };
    getSchedules();
  }, []);

  return { data, loading, error };
};

export default useDeliveryDate;
