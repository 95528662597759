import axios from 'axios';
import { GOOGLE_APIS, API_URL_V2 } from '../../server';
import { ParseGoogleResponse } from '../../utils/parseData/auth';

interface IEmailSignIn {
  email: string;
  password: string;
}
export const SIGN_IN_WITH_EMAIL = async ({ email, password }: IEmailSignIn) => {
  try {
    const { data } = await axios.post(`${API_URL_V2}/user/login-user`, {
      email,
      password,
    });
    return data;
  } catch (error) {
    return {
      error: true,
      message: error?.message,
    };
  }
};

export const SIGN_IN_WITH_GOOGLE = async (access_token: string) => {
  try {
    const { data: userData } = await axios.get(`${GOOGLE_APIS}/oauth2/v3/userinfo?access_token=${access_token}`);
    if (!userData.email_verified) {
      return;
    }
    const userInfo = ParseGoogleResponse(userData);
    const { data } = await axios.post(`${API_URL_V2}/user/google-auth`, {
      accessToken: access_token,
      userInfo,
    });
    return data;
  } catch (error) {
    return {
      error: true,
      message: error?.message,
    };
  }
};

export const SIGN_IN_WITH_FACEBOOK = async () => {
  try {
    const { data } = await axios.get(`${API_URL_V2}/user/federated-facebook`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
    });
    return data;
  } catch (error) {
    return {
      error: true,
      message: error?.message,
    };
  }
};
