import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineArrowForwardIos } from 'react-icons/md';

import Header from '../components/Layout/Header/Header';
import Categories from '../components/Categories/CategoriesList';
import Footer from '../components/Layout/Footer';
import ShopProducts from '../components/Route/ShopProducts/ShopProducts';
import Banners from '../components/Route/Banners/Banners';
import { ChooseRegionModal } from '../components/Home/ChooseRegion';

import { useAppSelector } from '../hooks/redux.hooks';
import styles from '../styles/styles';

const HomePage = () => {
  const { selectedCity } = useAppSelector((state) => state.configuration);
  const navigate = useNavigate();
  if (!selectedCity) {
    navigate('/choose-region/country');
  }
  return (
    <>
      <Header />
      <div className="mx-auto my-0 max-w-[80rem]">
        <section className="pb-14">
          <div className={`${styles.section} mb-12`} id="categories">
            <Link to="/categories" className="flex items-center py-6">
              <h4 className=" ml-2 font-semibold">Categorias</h4>
              <div className="ml-4 inline-block md:ml-8">
                <MdOutlineArrowForwardIos className="h-4 w-4  text-[#000]" />
              </div>
            </Link>
            <Categories />
          </div>
          <Banners />
          <ShopProducts />
        </section>
      </div>
      <Footer />
      {!selectedCity && <ChooseRegionModal />}
    </>
  );
};

export default HomePage;
