/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { AiOutlineCamera } from 'react-icons/ai';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks';
import { API_URL_V2 } from '../../../server';
import axios from 'axios';
import { toast } from 'react-toastify';
import { loadUser, updateUserInformation } from '../../../redux/actions/user';
import styles from '../../../styles/styles';
import { getNameFirstLetter } from '../../../utils/strings';

const UpdateUserInfo = () => {
  const { user, error, successMessage } = useAppSelector((state) => state.user);
  const [name, setName] = useState(user && user.name);
  const [email, setEmail] = useState(user && user.email);
  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error && !user) {
      toast.error(error);
      return;
    }
    dispatch({ type: 'clearErrors' });
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: 'clearMessages' });
    }
  }, [error, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        axios
          .put(
            `${API_URL_V2}/user/update-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            dispatch(loadUser());
            toast.success('avatar updated successfully!');
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <div className="mb-10 flex w-full justify-center">
        <div className="relative">
          {user?.avatar?.url && (
            <img
              src={`${user?.avatar?.url}`}
              className="h-[150px] w-[150px] rounded-full border-2 border-primary-200 object-cover"
              alt=""
            />
          )}

          {!user?.avatar?.url && user.name && (
            <div className="flex h-[150px] w-[150px] items-center justify-center rounded-full border-2 border-primary-200 bg-primary-100">
              <div className="text-center text-4xl font-black text-primary-500">{getNameFirstLetter(user.name)}</div>
            </div>
          )}

          <div className="absolute bottom-[5px] right-[5px] flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full bg-[#E3E9EE]">
            <input type="file" id="image" className="hidden" onChange={handleImage} />
            <AiOutlineCamera />
          </div>
        </div>
      </div>
      <div className="w-full px-5">
        <form onSubmit={handleSubmit}>
          <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 ">
            <div>
              <p className="pb-2">Nombre completo</p>
              <input type="text" className={`${styles.input} `} required value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div>
              <p className="pb-2">Correo</p>
              <input type="text" className={`${styles.input}`} required value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
              <p className="block pb-2">Número de teléfono</p>
              <input
                type="number"
                className={`${styles.input}`}
                required
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            <div>
              <p className="block pb-2">Ingresa tu constraseña</p>
              <input
                type="password"
                className={`${styles.input}`}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <button
            className={`mt-8 h-[40px] w-[250px] cursor-pointer rounded-lg border border-primary-200 text-center text-primary-200`}
            type="submit"
          >
            Actualizar
          </button>
        </form>
      </div>
    </>
  );
};

export default UpdateUserInfo;
