import Cookies from 'js-cookie';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import MKButton from '../../../UI/MKButton';
import { useAppDispatch } from '../../../../hooks/redux.hooks';
import { signUpWithPhoneNumberService } from '../../../../services/auth/login';
import { completeSignUpWithEmailAndOtp } from '../../../../services/auth/login';
import { loadUser } from '../../../../redux/actions/user';

interface ICompleteSignUpProps {
  registerToken: string;
  authenticationMethod: string;
}

const CompleteSignUp = ({ registerToken, authenticationMethod }: ICompleteSignUpProps) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const handleCompleteSignUp = async () => {
    if (name.length < 3) {
      toast.error('El nombre debe tener al menos 3 caracteres');
      return;
    }
    let response;
    if (authenticationMethod === 'email') {
      response = await completeSignUpWithEmailAndOtp(registerToken, name);
    } else {
      response = await signUpWithPhoneNumberService(registerToken, name);
    }

    if (response && response.success && response.token && response.customer) {
      Cookies.set('token', `${response.token}`);
      dispatch(loadUser());
      navigate('/');
      return;
    }
    toast.error(response?.message || 'Error al completar el registro');
  };

  return (
    <>
      <section className="flex flex-col gap-4 px-4">
        <section className="my-4">
          <p className="text-2xl font-semibold">¡Estás a un paso de registrarte!</p>
          <p className="text-base text-gray-500">Completa los siguientes datos para completar tu registro.</p>
        </section>

        <section className="flex flex-col gap-4">
          <section className="flex flex-col gap-2">
            <p>Nombre completo</p>
            <input
              className="rounded-xl bg-gray-200 px-4 py-4"
              placeholder="Ingresa tu nombre completo"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </section>
        </section>
        <MKButton className="mt-8 self-center px-12" onClick={handleCompleteSignUp}>
          <p className="text-lg font-semibold text-white">Completar registro</p>
        </MKButton>
      </section>
    </>
  );
};

export default CompleteSignUp;
