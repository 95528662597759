import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Lottie from 'react-lottie';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL_V2 } from '../server';
import animationData from '../Assests/animations/107043-success.json';
import motoAnimationData from '../Assests/animations/moto-running.json';
import { useAppDispatch } from '../hooks/redux.hooks';
import { loadUser } from '../redux/actions/user';

const ActivationPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { activation_token } = useParams();
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const motoAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: motoAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const handleGoToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    if (activation_token) {
      const sendRequest = async () => {
        await axios
          .post(`${API_URL_V2}/user/activation`, {
            activation_token,
          })
          .then((res) => {
            Cookies.set('token', `${res.data.token}`);
            setIsLoading(false);
            setError(false);
          })
          .catch((err) => {
            setError(true);
            setIsLoading(false);
          });
      };
      sendRequest();
    }
  }, []);

  useEffect(() => {
    if (!isLoading && !error) {
      dispatch(loadUser());

      let timer = setTimeout(() => {
        navigate('/');
        window.location.reload();
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <section>
          <div className="flex h-screen w-full flex-col items-center justify-center">
            <Lottie options={motoAnimationOptions} width={350} height={350} />
            <p className="text-xl font-bold">Estamos verificando su código, por favor espera</p>
          </div>
        </section>
      )}
      {!isLoading && (
        <div className="flex h-screen w-full flex-col items-center justify-center">
          {error ? (
            <p>Su código de activación ha expirado!</p>
          ) : (
            <>
              <div>
                <Lottie options={defaultOptions} width={350} height={350} />
              </div>
              <p className="text-xl font-bold">Cuenta creada con éxito!</p>
              <button onClick={handleGoToHome} className="mt-4 h-12 w-24 rounded-lg border border-primary-200 text-primary-200">
                Ir a inicio
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ActivationPage;
