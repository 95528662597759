import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AiOutlineLogin } from 'react-icons/ai';
import { TbAddressBook } from 'react-icons/tb';
import { RxPerson } from 'react-icons/rx';

import { useAppDispatch } from '../../hooks/redux.hooks';
import { clearAllShopCarts } from '../../redux/actions/cart';
import { API_URL_V2 } from '../../server';
import { logoutUserAction } from 'src/redux/actions/user';

const ProfileSidebar = ({ setActive, active }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logoutHandler = () => {
    axios
      .get(`${API_URL_V2}/user/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(clearAllShopCarts);
        Cookies.remove('token');
        dispatch(logoutUserAction());
        navigate('/');
      })
      .catch((error) => {});
  };

  return (
    <div className="w-full rounded-[10px] bg-white p-4 pt-8 shadow-sm">
      <div className="mb-8 flex w-full cursor-pointer items-center" onClick={() => setActive(1)}>
        <RxPerson size={20} className={`${active === 1 ? 'text-primary-200' : ''}`} />
        <span className={`pl-3 ${active === 1 ? 'text-primary-200' : ''} hidden 800px:block`}>Perfil</span>
      </div>
      {/* <div className="mb-8 flex w-full cursor-pointer items-center" onClick={() => setActive(2)}>
        <HiOutlineShoppingBag size={20} color={active === 2 ? 'red' : ''} />
        <span className={`pl-3 ${active === 2 ? 'text-[red]' : ''} hidden 800px:block`}>Pedidos</span>
      </div> */}
      {/* <div className="mb-8 flex w-full cursor-pointer items-center" onClick={() => setActive(3)}>
        <HiOutlineReceiptRefund size={20} color={active === 3 ? 'red' : ''} />
        <span className={`pl-3 ${active === 3 ? 'text-[red]' : ''} hidden 800px:block`}>Refunds</span>
      </div> */}

      {/* <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(4) || navigate("/inbox")}
      >
        <AiOutlineMessage size={20} color={active === 4 ? "red" : ""} />
        <span
          className={`pl-3 ${
            active === 4 ? "text-[red]" : ""
          } 800px:block hidden`}
        >
          Inbox
        </span>
      </div> */}

      {/* <div className="mb-8 flex w-full cursor-pointer items-center" onClick={() => setActive(5)}>
        <MdOutlineTrackChanges size={20} color={active === 5 ? 'red' : ''} />
        <span className={`pl-3 ${active === 5 ? 'text-[red]' : ''} hidden 800px:block`}>Rastrear pedido</span>
      </div> */}

      <div className="mb-8 flex w-full cursor-pointer items-center" onClick={() => setActive(7)}>
        <TbAddressBook size={20} className={`${active === 7 ? 'text-primary-200' : ''}`} />
        <span className={`pl-3 ${active === 7 ? 'text-primary-200' : ''} hidden 800px:block`}>Direcciones</span>
      </div>

      <div className="single_item mb-8 flex w-full cursor-pointer items-center" onClick={logoutHandler}>
        <AiOutlineLogin size={20} className={`${active === 8 ? 'text-primary-200' : ''}`} />
        <span className={`pl-3 ${active === 8 ? 'text-primary-200' : ''} hidden 800px:block`}>Cerrar sesión</span>
      </div>
    </div>
  );
};

export default ProfileSidebar;
