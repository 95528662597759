import { useState } from 'react';
import { toast } from 'react-toastify';
import { IoClose } from 'react-icons/io5';

import MKDrawer from '../../../UI/MKDrawer';
import { generateOTPCodeWithMail } from '../../../../services/auth/login';
import OtpVerificationDrawer from '../OtpVerificationDrawer';

interface IEmailDrawerProps {
  handleEmailDrawer: () => void;
}

const EmailDrawer = ({ handleEmailDrawer }: IEmailDrawerProps) => {
  const [isOtpVerificationOpen, setIsOtpVerificationOpen] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const result = await generateOTPCodeWithMail(email);

      if (!result || !result.success || !result?.data?.email) {
        toast.error('Ha ocurrido un error');
        return;
      }

      setIsOtpVerificationOpen(true);

      // dispatch(loadUser());
    } catch (error) {
      toast.error(error?.message || 'Es posible que tu email o correo no sean correctos');
    }
  };

  return (
    <MKDrawer>
      <section className="mx-4 mt-12">
        <button className="absolute right-4 top-1" onClick={handleEmailDrawer}>
          <IoClose className="h-8 w-8 hover:text-primary-500" />
        </button>
        {isOtpVerificationOpen ? (
          <OtpVerificationDrawer email={email} />
        ) : (
          <>
            <div>
              <p className="mb-3 text-2xl">Ingresa tu e-mail para iniciar sesión </p>
              <form className=" space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Correo electrónico
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      name="email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="group relative flex h-[40px] w-full justify-center rounded-md border border-transparent bg-primary-200 px-4 py-2 text-sm font-medium text-white hover:bg-primary-500"
                  >
                    Iniciar sesión
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </section>
    </MKDrawer>
  );
};

export default EmailDrawer;
