import { useState, useEffect } from 'react';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AiOutlineHeart, AiOutlineSearch } from 'react-icons/ai';
import { HiOutlineBuildingStorefront } from 'react-icons/hi2';
import { CgProfile } from 'react-icons/cg';
import { FiSun } from 'react-icons/fi';
import { CiReceipt } from 'react-icons/ci';

import useSearch from '../../../hooks/useSearch';
import HeaderRegion from './Region';
import Wishlist from '../../Wishlist/Wishlist';
import { useAppDispatch } from '../../../hooks/redux.hooks';
import { setProductSearch } from '../../../redux/slices/searchSlice';

import { extractLocalTimeComponents } from '../../../utils/dates';
import styles from '../../../styles/styles';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { updateSearch, search } = useSearch();
  const hour = extractLocalTimeComponents(new Date())[0];

  const welcomeMessage = Number(hour) <= 12 ? 'Buenos dias' : 'Buenas tardes';
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  const [searchTerm, setSearchTerm] = useState(search);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [activeUser, setActiveUser] = useState({ isAuthenticated, user });

  useEffect(() => {
    setActiveUser({ isAuthenticated, user });
  }, [user, isAuthenticated]);

  const handleSearchChange = () => {
    updateSearch(searchTerm);
    dispatch(setProductSearch(searchTerm));
    navigate({
      pathname: '/search',
      search: createSearchParams({
        product: searchTerm,
      }).toString(),
    });
  };

  const handleSearchInput = (e) => {
    const term = e.target.value || '';
    setSearchTerm(term);
  };

  return (
    <>
      <section className=" bg-white py-2 md:bg-zinc-950">
        <div className={`${styles.section} mx-auto my-0 flex max-w-[80rem] flex-col-reverse  justify-between md:items-center lg:flex-row`}>
          <p className="text-lg text-black md:text-white">
            {welcomeMessage} {activeUser.isAuthenticated && !isSeller ? activeUser?.user?.name : ''}
          </p>
          <section className="flex  items-center justify-between">
            <div className="relative mr-[15px] block cursor-pointer md:hidden">
              {activeUser.isAuthenticated ? (
                <Link to="/profile">
                  <img src={`${user?.avatar?.url}`} className="h-[35px] w-[35px] rounded-full object-cover" alt="" />
                </Link>
              ) : (
                <Link to="/login">
                  <CgProfile size={30} />
                </Link>
              )}
            </div>
            <FiSun className="hidden h-8 w-8 md:block" color={'#FDAA5D'} />
            <HeaderRegion />
          </section>
        </div>
      </section>
      <div className={`${styles.section}  mx-auto my-0 max-w-[80rem]`}>
        <div className=" items-center justify-between 800px:my-[20px] 800px:flex 800px:h-[50px]">
          <div className="hidden md:block">
            <Link to="/">
              <img className="h-20 w-20" src="/assets/mercoline-logo-300x300.png" alt="logo" />
            </Link>
          </div>

          {/* search box */}
          <div className="relative  my-2 w-full overflow-hidden 800px:w-[50%]">
            <input
              type="text"
              placeholder="Busca el producto que desees..."
              value={searchTerm}
              onChange={handleSearchInput}
              onKeyUpCapture={(e) => {
                e.preventDefault();
                if (e.key === 'Enter') {
                  handleSearchChange();
                }
              }}
              className="h-[40px] w-full rounded-full border-[2px] border-gray-300 pl-4 pr-16"
            />
            <button
              onClick={handleSearchChange}
              className="absolute right-0.5 top-0.5 flex w-14 cursor-pointer flex-col-reverse items-center rounded-full bg-black"
            >
              <AiOutlineSearch size={35} className="text-white" />
            </button>
          </div>

          <section className={`${styles.noramlFlex}`}>
            <div className="relative mr-[15px] cursor-pointer rounded-full border-2 p-2 hover:border-primary-200">
              <Link to="/orders">
                <CiReceipt size={30} className="text-primary-500" />
              </Link>
            </div>

            <div>
              <div
                className="relative mr-[15px] cursor-pointer rounded-full border-2 p-2 hover:border-primary-200"
                onClick={() => setOpenWishlist(true)}
              >
                <AiOutlineHeart size={30} className="text-primary-500" />
                <span className="top right font-mono absolute right-0 top-0 m-0 h-4 w-4 rounded-full bg-primary-500 p-0 text-center text-[12px] leading-tight text-primary-100">
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>
            {/* wishlist popup */}
            {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}

            <>
              <div className="relative hidden cursor-pointer md:block">
                {activeUser && activeUser.isAuthenticated ? (
                  <Link className="flex items-center rounded-lg border-2 px-4 py-1" to="/profile">
                    {activeUser?.user?.avatar?.url ? (
                      <img src={`${activeUser?.user?.avatar?.url}`} className="h-[35px] w-[35px] rounded-full border object-cover" alt="" />
                    ) : (
                      <CgProfile size={30} />
                    )}
                    <p className="ml-2">{activeUser.user?.name || ''}</p>
                  </Link>
                ) : (
                  <Link
                    className="h-14 rounded-xl border-2 px-3 py-3  hover:border-primary-200 hover:text-primary-500 focus:border-primary-200"
                    to="/login"
                  >
                    <span>Inicia sesión</span>
                  </Link>
                )}
              </div>
            </>
            <Link to={isSeller ? '/dashboard' : '/shop-login'} className="flex hidden items-center rounded-xl border border-black p-2">
              <HiOutlineBuildingStorefront className="mr-2" />
              {isSeller ? 'Administra tu negocio' : 'Accede a tu negocio'}
            </Link>
          </section>
        </div>
      </div>
    </>
  );
};

export default Header;
