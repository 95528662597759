import axios from 'axios';
import { API_URL_V2 } from '../../server';
import { setshopProducts } from '../slices/shopSlice';

// get all events
export const getAllShopsAndProducts = (radius, coordinates) => async (dispatch: any) => {
  try {
    const { data } = await axios.get(`${API_URL_V2}/shop/get-shop-and-products`, {
      params: {
        radius,
        coordinates,
      },
    });
    if (data?.shops) dispatch(setshopProducts(data.shops));
  } catch (error: any) {}
};
