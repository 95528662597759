import { useEffect, useState } from 'react';
import { API_URL_V2 } from '../server';
import axios from 'axios';

const useShopProducts = (id) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const getShopProducts = async () => {
      try {
        const { data } = await axios.get(`${API_URL_V2}/product/get-all-products-shop/${id}`);
        if (data) {
          setLoading(false);
        }
      } catch (error) {
        setError(true);
      }
    };
    getShopProducts();
  }, []);

  return { data, loading, error };
};

export default useShopProducts;
