import { useEffect, useRef, useState } from 'react';
import MKButton from '../../../UI/MKButton';
import { generateOTPCodeWithMail } from '../../../../services/auth/login';

const SendCodeAgain = ({ email }: { email: string }) => {
  const [loading, setLoading] = useState(false);
  // implementar countdown y dejar de contar en 0
  const [countdown, setCountdown] = useState(60 * 5);
  const countdownInterval = useRef<NodeJS.Timeout | null>(null);

  const handleSendCodeAgain = async () => {
    setLoading(true);
    await generateOTPCodeWithMail(email);
    setLoading(false);
    setCountdown(60 * 5);
  };

  useEffect(() => {
    if (countdown > 0) {
      clearInterval(countdownInterval.current as NodeJS.Timeout);
      countdownInterval.current = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      clearInterval(countdownInterval.current as NodeJS.Timeout);
    };
  }, [countdown]);

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(countdownInterval.current as NodeJS.Timeout);
    }
  }, [countdown]);

  return (
    <section>
      <MKButton disabled={loading || countdown > 0} variant="text" onClick={handleSendCodeAgain} className="w-full">
        {countdown > 0 ? (
          // show countdown in minutes and seconds - format 00:00
          <p className="text-center text-gray-400">
            Reenviar código en: {String(Math.floor(countdown / 60)).padStart(2, '0')}:{String(countdown % 60).padStart(2, '0')}
          </p>
        ) : (
          <p className="text-center text-primary-200">{loading ? 'Reenviando...' : 'Reenviar código'}</p>
        )}
      </MKButton>
    </section>
  );
};

export default SendCodeAgain;
