import { useState } from 'react';
import { toast } from 'react-toastify';
import { getCountryDataList, getEmojiFlag } from 'countries-list';

import CodeVerificationDrawer from './CodeVerificationDrawer';
import MKInput from '../../UI/MKInput';
import MKButton from '../../UI/MKButton';

import { SIGN_IN_WITH_PHONE_NUMBER } from '../../../services/auth/login';

const LoginNumberMethod = () => {
  const [phoneNumber, setphoneNumber] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedCountry, setselectedCountry] = useState('NI');

  const whiteList = ['US', 'NI', 'CA', 'ES']
  
  const allCountries = getCountryDataList().filter((item) => whiteList.includes(item.iso2.toUpperCase()));
  // Reglas de validación por país
  const phoneValidationRules = {
    US: /^[2-9]\d{2}[2-9]\d{2}\d{4}$/, // Ejemplo para EE.UU. (10 dígitos)
    NI: /^\d{8}$/,                     // Ejemplo para Nicaragua (8 dígitos)
    CA: /^[2-9]\d{2}[2-9]\d{2}\d{4}$/, // Ejemplo para Canadá (10 dígitos)
    ES: /^\d{9}$/,                     // Ejemplo para España (9 dígitos)
  };

  const validatePhoneNumber = (countryCode, phone) => {
    const regex = phoneValidationRules[countryCode];
    return regex ? regex.test(phone) : false;
  };

  const handleLoginWithPhoneNumber = async () => {
    if (!validatePhoneNumber(selectedCountry, phoneNumber)) {
      toast.error('Ingrese un número válido para el país seleccionado');
      return;
    }
    const sendOTPCodeResponse = await SIGN_IN_WITH_PHONE_NUMBER(phoneNumber);
    // const sendOTPCodeResponse = { success: true };
    if (sendOTPCodeResponse.success) {
      setOpenDrawer(true);
    }
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const handleSelectCountry = (value: string) => {
    setselectedCountry(value);
  };

  return (
    <>
      <section className="flex flex-col">
        <section className="flex flex-col justify-center">
          <h2 className="text-center text-base font-extrabold text-gray-900 md:mt-2 md:text-2xl">
            Ingresa tu número de teléfono para iniciar
          </h2>
        </section>
        <section className="my-2 flex w-full items-center rounded-xl border bg-gray-100 ">
          <select
            onChange={(e) => handleSelectCountry(e.target.value)}
            id="countries"
            className="ml-2 block w-10 border-r border-r-gray-400 bg-transparent text-xl text-gray-900 focus:border-primary-500 focus:ring-primary-500 "
          >
            {allCountries.map((country) => (
              <option value={country.iso2} selected={country.iso2 === selectedCountry}>
                <p className="text-3xl">{getEmojiFlag(country.iso2)}</p>
                <span className="ml-2 text-sm">{country.native}</span>
              </option>
            ))}
          </select>
          <MKInput
            className="ml-2 w-full rounded-none  border-none bg-transparent"
            type="number"
            placeholder="Número de teléfono"
            value={phoneNumber}
            onChange={(e) => setphoneNumber(e.target.value)}
          />
        </section>

        <MKButton onClick={handleLoginWithPhoneNumber} className="my-4 w-full self-center md:w-2/4">
          <p className="text-center text-white">Continuar</p>
        </MKButton>
      </section>
      {openDrawer && <CodeVerificationDrawer handleCloseDrawer={handleCloseDrawer} phoneNumber={phoneNumber} />}
    </>
  );
};

export default LoginNumberMethod;
