import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_URL_V2 } from '../../../server';
import styles from '../../../styles/styles';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(`${API_URL_V2}/user/update-user-password`, { oldPassword, newPassword, confirmPassword }, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.success);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="w-full px-5">
      <h1 className="block pb-2 text-center text-[25px] font-bold text-black">Cambiar Contraseña</h1>
      <div className="w-full">
        <section className="flex flex-col items-center">
          <div className=" mt-5 w-[100%] 800px:w-[50%]">
            <p className="block pb-2">Ingresa tu contraseña actual</p>
            <input
              type="password"
              className={`${styles.input} mb-4 !w-[95%] 800px:mb-0`}
              required
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>

          <div className=" mt-2 w-[100%] 800px:w-[50%]">
            <p className="block pb-2">Ingresa tu nueva contraseña</p>
            <input
              type="password"
              className={`${styles.input} mb-4 !w-[95%] 800px:mb-0`}
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>

          <div className=" mt-2 w-[100%] 800px:w-[50%]">
            <p className="block pb-2">Confirma tu nueva contraseña</p>
            <input
              type="password"
              className={`${styles.input} mb-4 !w-[95%] 800px:mb-0`}
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              className={`mt-8 h-[40px] w-[95%] cursor-pointer rounded-lg border border-primary-200 text-center text-primary-200`}
              value="Update"
              type="button"
              onClick={passwordChangeHandler}
            >
              Cambiar contraseña
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ChangePassword;
