// import ReactDOM from "react-dom";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PersistGate } from 'redux-persist/integration/react';

import Store, { persistor } from './redux/store';
import App from './App';

ReactDOM.createRoot(document.getElementById('root')).render(
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={Store}>
      <GoogleOAuthProvider clientId="311804426378-l0b16is5hnsfj1004ts396qqlrfr2vnf.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </PersistGate>
);

// reportWebVitals();
