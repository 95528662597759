import { useState, useEffect } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ProductsInCart from './ProductsInCart';
import CleanCart from './CleanCart';
import { useAppSelector } from '../../hooks/redux.hooks';
import MKModal, { CloseModal } from '../UI/MKModal';
import CustomerEmptyAddress from '../Common/CustomerEmptyAddress';

const Cart = ({ setOpenCart, shopId }) => {
  const { user, error, successMessage } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { cart } = useSelector((state) => state.cart);
  const [currentShopProducts, setCurrentShopProducts] = useState(cart[shopId]);
  const totalPrice = currentShopProducts?.products?.reduce((acc, item) => acc + item?.quantity * item?.price, 0);
  const [showModal, setShowModal] = useState(false);
  const handleCheckoutClick = () => {
    if (typeof user === 'undefined') {
      navigate(`/login`);
      return;
    }
    if (!Array.isArray(user?.addresses) || user?.addresses.length === 0) {
      setShowModal(true);
      return;
    }
    navigate(`/shop/${shopId}/checkout`);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setCurrentShopProducts(cart[shopId]);
  }, [cart, shopId]);

  return (
    <div className="fixed left-0 top-0 z-10 h-screen w-full bg-[#0000004b]">
      <div className="fixed right-0 top-0 flex h-full w-[80%] flex-col justify-between overflow-y-scroll bg-white shadow-sm 800px:w-[25%]">
        {!currentShopProducts || currentShopProducts?.products?.length === 0 ? (
          <div className="flex h-screen w-full items-center justify-center">
            <div className="fixed right-3 top-3 flex w-full justify-end pr-5 pt-5">
              <RxCross1 size={25} className="cursor-pointer" onClick={() => setOpenCart(false)} />
            </div>
            <h5>El carrito está vacío!</h5>
          </div>
        ) : (
          <section className="h-full overflow-hidden">
            {/* Cart Header */}
            <div className="h-[87%] px-2">
              <div className="flex w-full items-center justify-between pr-5 pt-5">
                <RxCross1 size={25} className="cursor-pointer" onClick={() => setOpenCart(false)} />
                <p>Carrito</p>
                <CleanCart shopId={shopId} />
              </div>

              {/* cart Single Items */}
              <br />
              <div className="h-[90%] w-full overflow-y-scroll pr-3">
                {Array.isArray(currentShopProducts?.products) &&
                  currentShopProducts?.products.length > 0 &&
                  currentShopProducts?.products && <ProductsInCart products={currentShopProducts.products} shopId={shopId} />}
              </div>
            </div>

            <section className="h-[13%] rounded-xl border-t-2 border-t-slate-200 px-5 py-3">
              {/* checkout buttons */}
              <div className="pb-2">
                <p className="text-center text-slate-500">Tus compras siempre seguras con</p>
                <p className="text-center text-primary-200">@merkoline</p>
              </div>
              <div className="flex items-center justify-between">
                <p>
                  subtotal: <span className="text-2xl font-black">C$ {totalPrice.toFixed(2)}</span>
                </p>
                <button onClick={handleCheckoutClick}>
                  <div className={`flex h-[45px] items-center justify-center rounded-xl bg-primary-200 px-8`}>
                    <h1 className=" text-[18px] font-[600] text-[#fff]">Checkout</h1>
                  </div>
                </button>
              </div>
            </section>
          </section>
        )}
      </div>
      {showModal && (
        <section className="relative">
          <MKModal>
            <section className="relative">
              <CloseModal className="z-10" onClick={handleCloseModal} />
              <CustomerEmptyAddress />
            </section>
          </MKModal>
        </section>
      )}
    </div>
  );
};

export default Cart;
