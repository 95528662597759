import { toast } from 'react-toastify';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import styles from '../../../../styles/styles';
import { createStripeSession } from '../../../../services/orders/createOrders';

interface StripePaymentPethodProps {
  totalPrice: number;
  setOrderPayload: () => Object;
}
const StripePaymentPethod = ({ totalPrice, setOrderPayload }: StripePaymentPethodProps) => {
  const elements = useElements();
  const stripe = useStripe();

  const handleCreateStripeSession = async () => {
    if (elements == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      toast.error(submitError.message);
      return;
    }

    const order = setOrderPayload();

    if (!order) {
      return;
    }

    const { data, error } = await createStripeSession(order);
    if (error || !data) {
      toast.error(error || 'Error al crear la sesión de Stripe');
      return;
    }
    const session = data.stripeSessionData;
    const stripeRedirect = await stripe.redirectToCheckout({ sessionId: session.id });

    if (stripeRedirect.error) {
      toast.error(stripeRedirect.error.message);
      return;
    }
  };

  return (
    <>
      <button onClick={handleCreateStripeSession} className={`${styles.button} w-full self-center`}>
        Pagar con Paypal C${totalPrice}
      </button>
    </>
  );
};

export default StripePaymentPethod;
